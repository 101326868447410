import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import API from '../api/api';

interface AuthContextType {
    isLoggedIn: boolean;
    accessToken: string;
    login: (accessToken: string, refreshToken: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [initializing, setInitializing] = useState(true); // Added initializing state

  useEffect(() => {
				const verifyToken = async (token: string): Promise<boolean> => {
					try {
							const response = await API.post('/api/v1/auth/validate-token', {
								headers: { Authorization: `Bearer ${token}` }
							});
							// If the request is successful and returns a 200 status, the token is valid
							return response.status === 200;
					} catch (error) {
							// If there is an error (e.g., non-200 response), assume the token is invalid
							return false;
					}
			};

      const initializeAuth = async () => {
          const storedAccessToken = localStorage.getItem('accessToken');
          const storedRefreshToken = localStorage.getItem('refreshToken');

          if (storedAccessToken && storedRefreshToken) {
              const isValid = await verifyToken(storedAccessToken);
              if (isValid) {
                  login(storedAccessToken, storedRefreshToken);
              } else {
                  logout(); // Logout if the token is invalid
              }
          }
          setInitializing(false); // Set initializing to false after check
      };

      console.log("Checking authentication status on load...");
      initializeAuth();
  }, []);

  const login = (accessToken: string, refreshToken: string) => {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      setAccessToken(accessToken);
      setIsLoggedIn(true);
  };

  const logout = () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setAccessToken('');
      setIsLoggedIn(false);
  };

  if (initializing) return <div>Loading...</div>;

  return (
      <AuthContext.Provider value={{ isLoggedIn, accessToken, login, logout }}>
          {children}
      </AuthContext.Provider>
  );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
