import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const MainLayout = () => {
  return (
    <>
      <Navbar />
      <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        <Outlet />
      </section>
      <Footer />
    </>
  );
};
export default MainLayout;