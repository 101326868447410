import { useState, useEffect } from 'react';
import API from '../api/api';

interface User {
    id: string;
    username: string;
    created_at: string;
    roles: Array<{
        name: string;
        description: string;
        id: string;
    }>;
};

export function useUserData(accessToken: string): User | null {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        async function fetchUserData() {
            try {
                const response = await API.get('/api/v1/users/me', {
                    headers: { Authorization: `Bearer ${accessToken}` }
                });
                setUser(response.data);
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        }

        fetchUserData();
    }, [accessToken]);

    return user;
}

interface LoginHistoryItem {
    user_agent: string;
    ip_address: string;
    created_at: string;
};

interface StatisticHistoryItem {
    user_id: string
    error_count: number
    keystrokes_count: number
    text_length: number
    typing_speed: number
    accuracy: number
    error_rate: number
    elapsed_time: number
    created_at: string
};

export function useLoginHistory(accessToken: string, currentPage: number, pageSize: number): { loginHistory: LoginHistoryItem[], totalRecords: number } {
    const [loginHistory, setLoginHistory] = useState<LoginHistoryItem[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        async function fetchLoginHistory() {
            const offset = (currentPage - 1) * pageSize;
            try {
                const response = await API.get(`/api/v1/logins/user/me?offset=${offset}&limit=${pageSize}`, {
                    headers: { Authorization: `Bearer ${accessToken}` }
                });
                setLoginHistory(response.data.items);
                setTotalRecords(response.data.count);
            } catch (error) {
                console.error('Failed to fetch login history:', error);
            }
        }

        fetchLoginHistory();
    }, [currentPage, pageSize, accessToken]);

    return { loginHistory, totalRecords };
}

export function useStatisticHistory(accessToken: string, currentPage: number, pageSize: number): { statisticsHistory: StatisticHistoryItem[], totalRecords: number } {
    const user = useUserData(accessToken);
    const userId = user?.id; // Assuming `id` is the field that holds the user ID

    const [statisticsHistory, setStatisticsHistory] = useState<StatisticHistoryItem[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        async function fetchStatisticsHistory() {
            if (!userId) return; // Ensure userId is available before making the request
            
            const offset = (currentPage - 1) * pageSize;
            try {
                const response = await API.get(`/api/v1/statistics/${userId}?offset=${offset}&limit=${pageSize}`, {
                    headers: { Authorization: `Bearer ${accessToken}` }
                });
                setStatisticsHistory(response.data.items);
                setTotalRecords(response.data.count);
            } catch (error) {
                console.error('Failed to fetch statistics history:', error);
            }
        }

        fetchStatisticsHistory();
    }, [currentPage, pageSize, accessToken, userId]);

    return { statisticsHistory, totalRecords };
}