import { useAuth } from '../contexts/AuthContext'; // Import the useAuth hook
import { useUserData } from '../hooks/useApiData';
import TypingTutor from '../components/TypingTrainer';

const TypingTrainerPage = () => {
    const { accessToken, logout } = useAuth();
    const user = useUserData(accessToken);

    return (
        <section>
            <div className="max-w-6xl px-4 py-6 mx-auto break-words mt-24 mb-24">
                <TypingTutor
                    user={user}
                />
            </div>
        </section>
    );
  };

  export default TypingTrainerPage;
