import React from 'react';
import { User, LoginHistory } from '../types';

interface UserProfileProps {
    user: User | null;
    setShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
    loginHistory: LoginHistory[];
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    onPageChange: (newPage: number) => void;
    onPageSizeChange: (newSize: number) => void;
}

const UserProfile: React.FC<UserProfileProps> = ({
    user,
}) => {
    return (
        <>
            <div className="max-w-2xl px-4 py-6 mx-auto">
                <h1 className="text-2xl font-semibold text-gray-800 dark:text-white">User Profile</h1>
                <form action="#">
                    <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5"></div>
                    {user && (
                        <div>
                            <div className="sm:col-span-2">
                                <label htmlFor="name" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">User Id</label>
                                <input type="text" name="name" id="name" className="bg-gray-50 read-only:bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" value={ user.id } placeholder="Type product name" readOnly/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="brand" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input type="text" name="brand" id="brand" className="bg-gray-50 read-only:bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" value={ user.username } placeholder="Product brand" readOnly/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="brand" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Created At</label>
                                <input type="text" name="brand" id="brand" className="bg-gray-50 read-only:bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" value={ user.created_at } placeholder="Product brand" readOnly/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="brand" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Roles</label>
                                <input type="text" name="brand" id="brand" className="bg-gray-50 read-only:bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" value={user.roles.map(role => role.name).join(', ')} placeholder="Product brand" readOnly/>
                            </div>
                        </div>
                        )}
                    <div className="flex items-center space-x-4">
                        <button type="submit" className="text-white mt-6 bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                            Update user
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default UserProfile;
