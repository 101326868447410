import React from 'react';
import { User, StatisticHistory } from '../types';

interface StatisticHistoryProps {
    user: User | null;
    setShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
    statisticHistory: StatisticHistory[];
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    onPageChange: (newPage: number) => void;
    onPageSizeChange: (newSize: number) => void;
}

const LoginHistoryComponent: React.FC<StatisticHistoryProps> = ({
    statisticHistory,
    totalRecords,
    currentPage,
    pageSize,
    onPageChange,
    onPageSizeChange
}) => {
    return (
        <>
            <div className="max-w-6xl px-4 py-6 mx-auto">
                <h1 className="text-2xl font-semibold text-gray-800 dark:text-white">Typing Statistic</h1>
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="text-center px-4 py-3">Date</th>
                                <th scope="col" className="text-center px-4 py-3">Text Length</th>
                                <th scope="col" className="text-center px-4 py-3">Keystrokes</th>
                                <th scope="col" className="text-center px-4 py-3">Error Count</th>
                                <th scope="col" className="text-center px-4 py-3">Typing Speed</th>
                                <th scope="col" className="text-center px-4 py-3">Accuracy</th>
                                <th scope="col" className="text-center px-4 py-3">Error Rate</th>
                                <th scope="col" className="text-center px-4 py-3">Elapsed Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statisticHistory.map((statistic, index) => (
                                <tr key={index} className="border-b dark:border-gray-700">
                                    <td className="text-center px-4 py-3">{new Date(statistic.created_at).toLocaleString()}</td>
                                    <td className="text-center px-4 py-3">{statistic.text_length}</td>
                                    <td className="text-center px-4 py-3">{statistic.keystrokes_count}</td>
                                    <td className="text-center px-4 py-3">{statistic.error_count}</td>
                                    <td className="text-center px-4 py-3">{(statistic.typing_speed ?? 0).toFixed(2)}</td>
                                    <td className="text-center px-4 py-3">{(statistic.accuracy ?? 0).toFixed(2)}</td>
                                    <td className="text-center px-4 py-3">{(statistic.error_rate ?? 0).toFixed(2)}</td>
                                    <td className="text-center px-4 py-3">{(statistic.elapsed_time ?? 0).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <nav className="flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                    <span className="text-sm text-gray-500 flex items-center">
                        Rows per page
                        <select 
                            onChange={(e) => onPageSizeChange(Number(e.target.value))}
                            value={pageSize}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-1.5 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            {[100, 200, 500].map(size => (
                                <option key={size} value={size}>{size}</option>
                            ))}
                        </select>
                    </span>

                    <div className="flex items-center space-x-2 text-sm text-gray-500">
                        <span>showing page</span>
                        <span className="font-semibold text-gray-900 dark:text-white">{currentPage}</span>
                        <span>of</span>
                        <span className="font-semibold text-gray-900 dark:text-white">{Math.ceil(totalRecords / pageSize)}</span>

                        <ul className="inline-flex items-center -space-x-px">
                            <li>
                                <button 
                                    onClick={() => onPageChange(currentPage - 1)} 
                                    disabled={currentPage === 1}
                                    className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    <span className="sr-only">Previous</span>
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </li>
                            <li>
                                <button 
                                    onClick={() => onPageChange(currentPage + 1)} 
                                    disabled={currentPage * pageSize >= totalRecords}
                                    className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    <span className="sr-only">Next</span>
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default LoginHistoryComponent;
