import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import GoogleOAuthCallback from './components/GoogleOAuthCallback';
import MainLayout from './layouts/MainLayout';
import HomePage from './pages/HomePage';
import UserProfilePage from './pages/UserProfilePage';
import LoginHistoryPage from './pages/LoginHistory';
import TypingTrainerPage from './pages/TypingTrainer';
import StatisticHistoryPage from './pages/StatisticHistory';

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/google-oauth-callback" element={<GoogleOAuthCallback />} />
                    <Route path='/' element={<AuthRoute component={MainLayout} />} >
                        <Route index element={<AuthRoute component={TypingTrainerPage} />} />
                        <Route path='user-profile' element={<AuthRoute component={UserProfilePage} />} />
                        <Route path='login-history' element={<AuthRoute component={LoginHistoryPage} />} />
                        <Route path='typing' element={<AuthRoute component={TypingTrainerPage} />} />
                        <Route path='statistics' element={<AuthRoute component={StatisticHistoryPage} />} />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
};

interface AuthRouteProps {
    component: React.ComponentType<any>;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ component: Component }) => {
    const { isLoggedIn } = useAuth();

    return isLoggedIn ? (
        <Component />
    ) : (
        <Navigate to="/login" replace={true} />
    );
};

// const AuthRoute: React.FC<AuthRouteProps> = ({ component: Component }) => {
//     const { isLoggedIn, initializing } = useAuth();

//     if (initializing) {
//         return <div>Loading...</div>; // or return null if you don't want to show a loading indicator
//     }

//     return isLoggedIn ? (
//         <Component />
//     ) : (
//         <Navigate to="/login" replace={true} />
//     );
// };

export default App;


