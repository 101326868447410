import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext'; // Import the useAuth hook
import { useUserData, useLoginHistory } from '../hooks/useApiData';
import UserProfile from "../components/UserProfile";

const UserProfilePage = () => {
    const { accessToken, logout } = useAuth(); // Access accessToken and logout from the context
    const [showProfile, setShowProfile] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const user = useUserData(accessToken);
    const { loginHistory, totalRecords } = useLoginHistory(accessToken, currentPage, pageSize);

    return (
        <>
            <UserProfile
                user={user}
                setShowProfile={setShowProfile}
                loginHistory={loginHistory}
                totalRecords={totalRecords}
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={setCurrentPage}
                onPageSizeChange={setPageSize}
            />
        </>
    );
};

export default UserProfilePage;
