import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';  // Import the useAuth hook

const GoogleOAuthCallback: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { login } = useAuth();  // Destructure the login function from useAuth

    useEffect(() => {
        const handleGoogleOAuth = async () => {
            const queryString = location.search; // Keep the '?' for GET request

            if (!queryString) {
                alert('No parameters found in callback URL.');
                navigate('/login');  // Redirect to login on failure
                return;
            }

            try {
                const url = `/api/v1/auth/google-code${queryString}`;
                const response = await axios.get(url);
                const { access_token, refresh_token } = response.data;
                login(access_token, refresh_token);  // Use login from the context
                navigate('/'); // Redirect to home on success
            } catch (error) {
                alert('Failed to exchange code for tokens');
                console.error('Google OAuth error:', error);
                navigate('/login');
            }
        };

        handleGoogleOAuth();
    }, [location, navigate, login]);  // Update dependencies

    return (
        <div>Loading...</div>
    );
};

export default GoogleOAuthCallback;
