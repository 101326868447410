import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

// Type definition for Tokens
interface Tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

// Function to get tokens from local storage
const getTokens = (): Tokens => ({
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken')
});

// Function to save tokens to local storage
const saveTokens = (accessToken: string, refreshToken: string): void => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

// Create an Axios instance
const API: AxiosInstance = axios.create();

// Request Interceptor to add Authorization header
API.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const { accessToken } = getTokens();

  // Initialize request.headers if not present
  request.headers = request.headers || {};
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return request;
}, (error) => Promise.reject(error));

// Response Interceptor to handle token refresh
API.interceptors.response.use(undefined, async (error) => {
  const originalRequest: AxiosRequestConfig & { _retry?: boolean } = error.config;
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const { refreshToken } = getTokens();
      const response = await axios.post('/api/v1/auth/refresh-token', { refresh_token: refreshToken });
      const { access_token, refresh_token } = response.data;
      saveTokens(access_token, refresh_token);

      originalRequest.headers = originalRequest.headers || {};
      originalRequest.headers['Authorization'] = `Bearer ${access_token}`;

      return API(originalRequest);
    } catch (refreshError) {
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
});

export default API;
