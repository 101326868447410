import React, { useEffect, useRef, useState, useCallback } from 'react';
import { encode, decode } from 'msgpack-lite';

interface TypingTutorProps {
    user: any; // The user can be null initially
}

const TypingTutor: React.FC<TypingTutorProps> = ({ user }) => {
    const ws = useRef<WebSocket | null>(null);

    // States for managing the text to type, user input, current index, and correctness
    const [textToType, setTextToType] = useState('Wait for the text to be updated...');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [typingAccuracy, setTypingAccuracy] = useState<Array<boolean | undefined>>(Array(textToType.length).fill(undefined));
    const [typingSpeed, setTypingSpeed] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [errorRate, setErrorRate] = useState(0);

    // States for total statistics
    const [totalTypingSpeed, setTotalTypingSpeed] = useState<number | null>(null);
    const [totalAccuracy, setTotalAccuracy] = useState<number | null>(null);
    const [totalErrorRate, setTotalErrorRate] = useState<number | null>(null);

    const fetchTotalStatistics = useCallback(async (userId: string) => {
        const totalsEndpoint = `/api/v1/statistics/totals/${userId}`;

        try {
            const response = await fetch(totalsEndpoint, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            });

            if (response.ok) {
                const totals = await response.json();

                // Safely handle undefined or null values from the API
                setTotalTypingSpeed(totals.typing_speed !== undefined ? totals.typing_speed : 0);
                setTotalAccuracy(totals.accuracy !== undefined ? totals.accuracy : 0);
                setTotalErrorRate(totals.error_rate !== undefined ? totals.error_rate : 0);
            } else {
                console.log('Failed to fetch total statistics');
                setTotalTypingSpeed(0); // Fallback to 0 if the request fails
                setTotalAccuracy(0);
                setTotalErrorRate(0);
            }
        } catch (error) {
            console.error('Error fetching total statistics:', error);
            setTotalTypingSpeed(0); // Fallback to 0 if there's an error
            setTotalAccuracy(0);
            setTotalErrorRate(0);
        }
    }, []);

    const updateTextToType = useCallback(async (text: string) => {
        console.log("Text Update:", text);  // Debug log

        // Update the text to type
        setTextToType(text);
        setCurrentIndex(0);

        // Reset typing accuracy for the new text
        setTypingAccuracy(() => {
            const newAccuracy = Array(text.length).fill(undefined);
            return newAccuracy;
        });

        // Fetch and update total statistics whenever new text is received
        if (user) {
            await fetchTotalStatistics(user.id);
        }
    }, [user, fetchTotalStatistics]);

    // Handle incoming messages from the server
    const handleServerMessage = useCallback((message: any) => {
        console.log("Server Message:", message);  // Debug log
        switch (message.message_type) {
            case "MessageTextUpdate":
                updateTextToType(message.text);
                break;
            case "MessageStatisticsUpdate":
                updateStatistics(message);
                break;
            default:
                console.log("Unknown message type:", message.message_type);
        }
    }, [updateTextToType]);

    const updateStatistics = (message: any) => {
        setTypingSpeed(message.typing_speed);
        setAccuracy(message.accuracy);
        setErrorRate(message.error_rate);
    };

    // Initialize WebSocket connection and fetch statistics when user is available
    useEffect(() => {
        if (user) {
            const token = localStorage.getItem('accessToken');
            if (token) {
                const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
                const wsBaseUrl = `${protocolPrefix}//${window.location.host}/ws?token=${encodeURIComponent(token)}`;

                ws.current = new WebSocket(wsBaseUrl);
                ws.current.binaryType = 'arraybuffer';

                ws.current.onclose = () => console.log("WebSocket closed");
                ws.current.onerror = (error) => console.log("WebSocket error:", error);
                ws.current.onmessage = (event) => {
                    const data = decode(new Uint8Array(event.data));
                    handleServerMessage(data);
                };

                // Fetch total statistics once the WebSocket connection is established
                fetchTotalStatistics(user.id);
            } else {
                console.log("No access token available. Cannot establish WebSocket connection.");
            }
        }

        // Cleanup WebSocket connection on component unmount
        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, [user, handleServerMessage, fetchTotalStatistics]); // Depend on the user prop

    // Handle user key presses
    const handleKeyPress = useCallback((event: KeyboardEvent) => {
        const validKeys = /^[a-zA-Z0-9 .,?!'";:\-]$/;
        const lastChar = event.key;
    
        // Ignore service keys and handle the Backspace
        if (!validKeys.test(lastChar) && lastChar !== 'Backspace') {
            return;
        }
    
        if (lastChar === 'Backspace') {
            // Check if currentIndex is not the first character
            if (currentIndex > 0) {
                setTypingAccuracy(prevAccuracy => {
                    const newAccuracy = [...prevAccuracy];
                    newAccuracy[currentIndex - 1] = undefined; // Reset the accuracy of the character before the current index
                    return newAccuracy;
                });
                setCurrentIndex(prevIndex => prevIndex - 1);
            }
            return;
        }
    
        // Update the typing accuracy first using the current index
        setTypingAccuracy(prevAccuracy => {
            const newAccuracy = [...prevAccuracy];
            if (textToType[currentIndex] === lastChar) {
                newAccuracy[currentIndex] = true; // Correct key
            } else {
                newAccuracy[currentIndex] = false; // Incorrect key
            }
            return newAccuracy;
        });
    
        // Log the key press
        console.log("Key Press:", lastChar);  // Debug log
    
        // Send a message about the key press
        sendMessage({
            message_type: "MessageKeyPress",
            key: lastChar,
            timestamp: Date.now(),
            index: currentIndex
        });
    
        // Finally, update the current index
        setCurrentIndex(prevIndex => prevIndex + 1);
    }, [currentIndex, textToType, setTypingAccuracy, setCurrentIndex]);

    const sendMessage = useCallback((message: any) => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(encode(message));
        }
    }, []);

    // Add keydown event listener
    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <div>
            <div style={{ fontFamily: 'monospace', fontSize: '24px', whiteSpace: 'pre-wrap' }}>
                {/* Display the text with color coding */}
                {textToType.split('').map((char, index) => {
                    let color = 'lightgrey';
    
                    if (typingAccuracy[index] === false) {
                        color = 'red';
                    } else if (index === currentIndex) {
                        color = 'purple';
                    } else if (typingAccuracy[index] === true) {
                        color = 'black';
                    }

                    return (
                        <span key={index} style={{ color: color  }}>
                            {char === ' ' ? '_' : char}
                        </span>
                    );
                })}
            </div>
            <div className="flex justify-between mt-12">
                <div className="text-sm">
                    <span className="text-gray-500 font-semibold">Speed:</span>
                    <span className="text-gray-500 ml-2">{typingSpeed.toFixed(2)} WPM</span>
                </div>
                <div className="text-sm">
                    <span className="text-gray-500 font-semibold">Accuracy:</span>
                    <span className="text-gray-500 ml-2">{accuracy.toFixed(2)}%</span>
                </div>
                <div className="text-sm">
                    <span className="text-gray-500 font-semibold">Error Rate:</span>
                    <span className="text-gray-500 ml-2">{errorRate.toFixed(2)}%</span>
                </div>
            </div>
            <div className="flex justify-between mt-4">
                <div className="text-sm">
                    <span className="text-gray-500 font-semibold">Average Speed:</span>
                    <span className="text-gray-500 ml-2">{totalTypingSpeed !== null ? totalTypingSpeed.toFixed(2) : 'N/A'} WPM</span>
                </div>
                <div className="text-sm">
                    <span className="text-gray-500 font-semibold">Average Accuracy:</span>
                    <span className="text-gray-500 ml-2">{totalAccuracy !== null ? totalAccuracy.toFixed(2) : 'N/A'}%</span>
                </div>
                <div className="text-sm">
                    <span className="text-gray-500 font-semibold">Average Error Rate:</span>
                    <span className="text-gray-500 ml-2">{totalErrorRate !== null ? totalErrorRate.toFixed(2) : 'N/A'}%</span>
                </div>
            </div>
        </div>
    );
};

export default TypingTutor;
